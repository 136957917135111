import React, { ComponentProps } from 'react';

import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

export default function setup(
  node: ReactDOM.Container,
  props: ComponentProps<typeof ProjectTableApp>
) {
  const ProjectTableApp = createLazyComponent(() => import('./ProjectTableApp'));
  attachRoot(<ProjectTableApp {...props} />, node);
}
